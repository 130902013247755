<template>
  <div style="position: relative;">
    <img class="login-box-cover-img" src="../assets/image/admin_woyaoyou_bg_login@2x.png" />


    <section>

      <!-- <div class="color"></div>

      <div class="color"></div>

      <div class="color"></div> -->

      <div class="boxx">

        <div class="text-center">
          <img
            style="height: 92px;"
            v-if="siteInfo != null"
            :src="siteInfo.logo_round"
          />
        </div>

        <!-- <div class="square" style="--i:0;"></div>

        <div class="square" style="--i:1;"></div>

        <div class="square" style="--i:2;"></div>

        <div class="square" style="--i:3;"></div>

        <div class="square" style="--i:4;"></div> -->

        <div class="container">

          <div class="form">

            <div class="text-center ft24 ftw600 cl-w">欢迎登录</div>
            <form>

              <div class="inputBox">

                <a-input v-model="form.username" prefix="登录帐号" :maxLength="11" class="username"></a-input>

              </div>

              <div class="inputBox">

                <a-input type="password" v-model="form.password" prefix="登录密码" class="pwd"></a-input>

              </div>

              <div class="inputBox">

                <a-button class="abtna" @click="loginAct" type="primary">立即登录</a-button>

              </div>

            </form>

          </div>

        </div>

      </div>

    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      code: "",
      form: {
        username: "admin",
        password: "123456",
      },
      siteInfo: null,
    };
  },
  created() {
    this.getCode();
    this.getSiteInfo();
  },

  mounted() {
    //绑定事件
    window.addEventListener("keydown", this.keyDown);
  },
  methods: {
    getSiteInfo() {
      this.$http
        .api("platform/siteInfo")
        .then((res) => {
          this.siteInfo = res.site;
        })
        .catch((res) => {
          //console.log(res);
        });
    },
    getCode() {
      this.$http
        .api("platform/admin/getCode", {})
        .then((res) => {
          this.code = res.code;
        })
        .catch((res) => {
          //console.log(res);
        });
    },
    loginAct() {
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .api("platform/admin/login", {
          username: this.form.username,
          password: this.form.password,
        })
        .then((res) => {
          // console.log(res);
          //localStorage.setItem('admin-token',res.auth_token);
          localStorage.setItem("tokenHeader", res.tokenHead);
          localStorage.setItem("Authorization", res.auth_token);
          this.$message.success("登录成功", 1, () => {
            this.$router.push("/home");
          });
        })
        .catch((res) => {
          //console.log(res);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    keyDown(e) {
      //如果是回车则执行登录方法
      if (e.keyCode == 13) {
        this.loginAct();
      }
    },
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
  },
};
</script>

<style>
.abtna{
  width: 100%!important;
  height: 50px!important;
  color:#fff!important;
  border: 1px solid rgb(255, 255, 255, 0.5)!important;
  background: rgb(255, 255, 255, 0.2) !important ;
  /* transition:background-size 0.2s !important; */
}
.inputBox .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 98px;
  height: 50px;
}

.inputBox .ant-input-affix-wrapper .ant-input-prefix {
  color: #fff;
  font-size: 16px;
}

.inputBox .ant-input-affix-wrapper .ant-input-prefix {
  left: 30px;
}

.login-box-cover-img {
  position: absolute;
  bottom: 0;
  width: 100vw;
}

section {

  display: flex;

  justify-content: center;

  align-items: center;

  min-height: 100vh;

  background: linear-gradient(135deg, #4772ff 0%, #5851ff 100%);

}

section .color {

  position: absolute;

  filter: blur(150px);

}

section .color:nth-child(1) {

  top: -350px;

  width: 600px;

  height: 600px;

  background: #ff359b;

}

section .color:nth-child(2) {

  bottom: -150px;

  left: 100px;

  width: 500px;

  height: 500px;

  background: #fffd87;

}

section .color:nth-child(3) {

  bottom: 50px;

  right: 100px;

  width: 500px;

  height: 500px;

  background: #00d2ff;

}

.boxx {

  position: relative;

}

.boxx .square {

  position: absolute;

  backdrop-filter: blur(5px);

  box-shadow: 0 25px 45px rgb(0, 0, 0, 0.1);

  border: 1px solid rgb(255, 255, 255, 0.5);

  border-right: 1px solid rgb(255, 255, 255, 0.2);

  border-bottom: 1px solid rgb(255, 255, 255, 0.2);

  background: rgb(255, 255, 255, 0.1);

  border-radius: 10px;

  animation: animate 10s linear infinite;

  animation-delay: calc(-1s*var(--i));

}

/* 动画 */

@keyframes animate {

  0%,
  100% {

    transform: translateY(-40px);

  }

  50% {

    transform: translate(40px);

  }

}

.boxx .square:nth-child(1) {

  top: -50px;

  right: -60px;

  width: 100px;

  height: 100px;

}

.boxx .square:nth-child(2) {

  top: 150px;

  left: -100px;

  width: 120px;

  height: 120px;

  z-index: 2;

}

.boxx .square:nth-child(3) {

  bottom: 50px;

  right: -60px;

  width: 80px;

  height: 80px;

  z-index: 2;

}

.boxx .square:nth-child(4) {

  bottom: -80px;

  left: 100px;

  width: 50px;

  height: 50px;

}

.boxx .square:nth-child(5) {

  top: -90px;

  left: 140px;

  width: 60px;

  height: 60px;

}

.container {

  position: relative;

  width: 400px;

  min-height: 362px;

  background: rgb(255, 255, 255, 0.1) !important;

  border-radius: 24px;

  display: flex;

  justify-content: center;

  align-items: center;

  backdrop-filter: blur(5px);

  -webkit-backdrop-filter: blur(5px);

  box-shadow: 0 25px 45px rgb(0, 0, 0, 0.1);

  border: 1px solid rgb(255, 255, 255, 0.5);

  border-right: 1px solid rgb(255, 255, 255, 0.2);

  border-bottom: 1px solid rgb(255, 255, 255, 0.2);

  margin-top: 36px;

}

.form {

  position: relative;

  height: 100%;

  width: 100%;

  padding: 40px;

}

.form h2 {

  position: relative;

  color: #fff;

  font-size: 24px;

  font-weight: 600;

  letter-spacing: 1px;

  margin-bottom: 40px;

}

.form h2::before {

  content: '';

  position: absolute;

  left: 0;

  bottom: -10px;

  width: 80px;

  height: 4px;

  background: #fff;

}

.form .inputBox {

  width: 100%;

  margin-top: 32px;

}

.form .inputBox .ant-btn {
  height: 50px;
  border-radius: 25px;
  font-size: 16px;
}

.form .inputBox input {

  width: 100%;

  background: rgb(255, 255, 255, 0.2);

  border: none;

  outline: none;

  padding: 10px 20px;

  border-radius: 35px;

  border: 1px solid rgb(255, 255, 255, 0.5);

  border-right: 1px solid rgb(255, 255, 255, 0.2);

  border-bottom: 1px solid rgb(255, 255, 255, 0.2);

  font-size: 16px;

  letter-spacing: 1px;

  color: #fff;

  box-shadow: 0 5px 15px rgb(0, 0, 0, 0.05);

}

.form .inputBox input::placeholder {

  color: #fff;

}

.form .inputBox input[type="submit"] {

  background: #fff;

  color: #666;

  max-width: 100px;

  cursor: pointer;

  margin-bottom: 20px;

  font-weight: 600;

}

.forget {

  margin-top: 5px;

  color: #fff;

}

.forget a {

  color: #fff;

  font-weight: 600;

}
</style>
